/* eslint-disable no-plusplus */
// import React, { useRef, useCallback, useEffect, useState } from 'react';
import React, { useCallback } from 'react';
// import { Link } from 'react-router-dom';

import FileSaver from 'file-saver';
// import axios from 'axios';

import { Container, Content, ContentArea } from './styles';

import Button from '../../components/Button';

import ConfigurationMenu from '../../components/ConfigurationMenu';

const BackupDatabase: React.FC = () => {
  const handleDownload = useCallback(() => {
    FileSaver.saveAs(
      'https://officemanager.sharksolutions.com.br/dump-officemanager.ofm',
      'dump-officemanager.ofm',
    );
  }, []);

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h2>Backup do Banco de Dados</h2>
          <Button type="button" onClick={handleDownload}>
            Download
          </Button>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default BackupDatabase;
