/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { isBefore, parseISO, format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit3, FiXCircle, FiCheckSquare } from 'react-icons/fi';
// import { FiEdit3, FiXCircle } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  Section,
  IntimationList,
  FormContainer,
  Avatar,
} from './styles';

import api from '../../services/api';

import LeftMenu from '../../components/LeftMenu';
import Button from '../../components/Button';

interface Client {
  id: string;
  name: string;
  cpf: string;
  password: string;
}

interface City {
  id: string;
  name: string;
  uf: string;
}

interface ClientComplete {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
}

interface ClientProcess {
  id: string;
  process_number: string;
  folder: string;
  client: Client;
}

interface IntimationType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface Responsible {
  id: string;
  user: User;
}

interface AttendanceType {
  id: string;
  name: string;
}

interface Intimation {
  id: string;
  description: string;
  deadline: string;
  clientProcess: ClientProcess;
  created_at: Date;
  creationDate: string;
  formattedDeadline: string;
  expiredDeadline: boolean;
  intimationType: IntimationType;
  responsibles: Responsible[];
  attendanceType: AttendanceType;
  user: User;
  avatar_url: string;
}

const Intimation: React.FC = () => {
  const history = useHistory();
  const [intimations, setIntimations] = useState<Intimation[]>([]);
  // const [selectedClient, setSelectedClient] = useState<ClientComplete>();

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    api.get('/intimation').then((response) => {
      const intimationFormatted = response.data.map((item: Intimation) => {
        const d = new Date(`${item.deadline}T00:00:00`);
        return {
          ...item,
          creationDate: formatDate(item.created_at),
          formattedDeadline: isValid(d)
            ? format(d, " dd'/'MM'/'yyyy", {
                locale: ptBR,
              })
            : '',
          expiredDeadline: isBefore(parseISO(item.deadline), new Date()),
          avatar_url: item.user ? `https://app-gobarberamx.s3.amazonaws.com/${item.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
        };
      });

      console.log('Intimação', intimationFormatted);

      setIntimations(intimationFormatted);
    });
  }, []);

  const handleCallNewIntimation = useCallback(() => {
    history.push('/new-intimation');
  }, [history]);

  const handleDeleteIntimation = useCallback(async (intimationId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await api.delete(`/intimation/${intimationId}`).then((response) => {
        setIntimations((item) =>
          item.filter((_item) => _item.id !== intimationId),
        );
      });
    }
  }, []);

  const handleCallUpdateIntimation = useCallback(
    (intimation: Intimation) => {
      history.push('/update-intimation', {
        intimation,
      });
    },
    [history],
  );

  const handleCallFinishIntimation = useCallback(
    (intimation: Intimation) => {
      history.push('/finish-intimation-list-item', {
        intimation,
      });
    },
    [history],
  );

  const handleCallClientView = useCallback(
    async (client: Client) => {
      await api.get(`/clients/cpf/${client.cpf}`).then((response) => {
        const cl: ClientComplete = response.data;

        localStorage.setItem(
          '@Rastreio:selectedClient',
          JSON.stringify(cl),
        );
        history.push('/update-client', { selectedClient: cl, caller: 'intimation' });
      });
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Prazos</h1>

          <FormContainer>
            <Button type="button" onClick={handleCallNewIntimation}>
              Novo
            </Button>
          </FormContainer>

          <Section>
            <IntimationList key="aswqw">
              <div>
                <strong>
                  <b>Usuário</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Cliente</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Tipo</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Prazo</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Descrição</b>
                </strong>
              </div>

              <div>
                <strong>
                  <b>Responsáveis</b>
                </strong>
              </div>

              <div>
                <strong>&nbsp;</strong>
              </div>
            </IntimationList>
            {intimations.map((item) => (
              <IntimationList key={item.id}>
                <Avatar>
                  {item.user ? (
                    <img src={item.avatar_url} alt={item.user.name} title={item.user.name} />
                  ) : (
                    <img
                      src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                      alt="avatar"
                    />
                  )}
                </Avatar>
                <div>
                  <Link
                    to="/intimation"
                    title="Clique para dados do cliente"
                    onClick={() => {
                      item && handleCallClientView(item.clientProcess.client);
                    }}
                  >
                    <strong>{item.clientProcess.client.name}</strong>
                  </Link>
                </div>

                <div>
                  <strong>{item.attendanceType.name}</strong>
                </div>

                <div>
                  <strong
                    className={item.expiredDeadline ? 'expiredDeadline' : ''}
                  >
                    {item.deadline ? item.formattedDeadline : 'Sem data'}
                  </strong>
                </div>

                <div>
                  <strong>{item.description}</strong>
                </div>

                <div>
                  <ul>
                    {item.responsibles.map((responsible) => (
                      <li key={responsible.id}>{responsible.user.name}</li>
                    ))}
                  </ul>
                </div>

                <div>
                  <FiEdit3
                    title="Editar"
                    onClick={() => {
                      item.id && handleCallUpdateIntimation(item);
                    }}
                  />

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      item.id && handleDeleteIntimation(item.id);
                    }}
                  />

                  <FiCheckSquare
                    title="Concluir"
                    onClick={() => {
                      item.id && handleCallFinishIntimation(item);
                    }}
                  />
                </div>
              </IntimationList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default Intimation;
