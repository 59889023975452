import React from 'react';

import { FiPower, FiHome, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import { Container } from './styles';
import logoImg from '../../assets/logo.png';

const LeftMenu: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <Container>
      <nav>
        <header>
          {user.avatar_url ? (
            <img src={user.avatar_url} alt={user.name} />
          ) : (
            <img
              src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              alt="avatar"
            />
          )}

          <Link to="/profile">
            <h2>{user.name}</h2>
          </Link>
        </header>
        <ul>
          <li>
            <FiHome />
            <Link to="/dashboard">
              <span>Home</span>
            </Link>
          </li>

          <li>
            <FiChevronRight />
            <Link to="/process-type">
              <span>Tipo de Processo</span>
            </Link>
          </li>
          <li>
            <FiChevronRight />
            <Link to="/competence">
              <span>Competência</span>
            </Link>
          </li>
          <li>
            <FiChevronRight />
            <Link to="/attendance-type">
              <span>Tipo de Andamento</span>
            </Link>
          </li>
          <li>
            <FiChevronRight />
            <Link to="/clients-list">
              <span>Clientes</span>
            </Link>
          </li>

          {user.name === 'Alexandre' || user.name === 'Marcos Tonelli Veras' ? (
            <li>
              <FiChevronRight />
              <Link to="/user-restriction">
                <span>Horário de uso</span>
              </Link>
            </li>
          ) : (
            <li>&nbsp;</li>
          )}

          {user.name === 'Alexandre' || user.name === 'Marcos Tonelli Veras' ? (
            <li>
              <FiChevronRight />
              <Link to="/backup-database">
                <span>Backup</span>
              </Link>
            </li>
          ) : (
            <li>&nbsp;</li>
          )}

          <li>
            <button type="button" onClick={signOut}>
              <FiPower />
              <span>Sair</span>
            </button>
          </li>
        </ul>
        <footer>
          <img src={logoImg} alt="logo" />
        </footer>
      </nav>
    </Container>
  );
};

export default LeftMenu;
