/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Container, Content, ContentArea, FormContainer } from './styles';

import api from '../../../../services/api';

import LeftMenu from '../../../../components/LeftMenu';
import InputWithLabel from '../../../../components/InputWithLabel';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';

interface ContactClientFormData {
  attendance_date: string;
  folder: string;
  description: string;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  folder: string;
  client: Client;
}

interface Location {
  id: string;
  client: Client;
  process_attendance_id: string;
  contact_date: string;
  clientProcess: ClientProcess;
}

interface ProcessAttendance {
  clientProcess: ClientProcess;
}

const FinishContactClientListItem: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const [clientProcess, setClientProcess] = useState<ClientProcess>();

  const locationState: Location = location.state as Location;

  // console.log('Location', location.state);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  /*
  useEffect(() => {
    api
      .get(`/process-attendance/id/${locationState.process_attendance_id}`)
      .then((response) => {
        const processAttendance: ProcessAttendance = response.data;
        setClientProcess(processAttendance.clientProcess);
      });
  }, [locationState.process_attendance_id]);
  */

  // console.log('Process Attendance', processAttendance);

  useEffect(() => {
    if (locationState) {
      console.log(locationState);
      setClientProcess(locationState.clientProcess);
      formRef.current?.setData({ folder: locationState.clientProcess.folder });
      // formRef.current?.setData({ attendance_date: locationState.contact_date });
      formRef.current?.setData({
        attendance_date: new Date().toISOString().slice(0,10)
      });
    }
  }, [clientProcess, locationState]);

  const handleSubmit = useCallback(
    async (data: ContactClientFormData) => {
      try {
        // console.log(attendanceType);
        // console.log(clientProcess);

        if (clientProcess) {
          const attendanceType = await api.get(
            '/attendance-types/name/Contato com o Cliente',
          );

          /*
          await api.put('/process-attendance', {
            id: locationState.process_attendance_id,
            client_id: locationState.client.id,
            attendance_type_id: attendanceType.data.id,
            client_process_id: clientProcess?.id,
            description: data.description,
            attendance_date: data.attendance_date,
          });
          */
          await api.post('/process-attendance', {
            client_id: locationState.client.id,
            attendance_type_id: attendanceType.data.id,
            client_process_id: clientProcess.id,
            description: data.description,
            attendance_date: format(new Date(), " yyyy'-'MM'-'dd", {
              locale: ptBR,
            }),
            user_id: user.id,
          });

          await api.put('/contact-client-list', {
            id: locationState.id,
            client_id: locationState.client.id,
            contact_date: data.attendance_date,
            done: true,
          });

          history.push('/contact-client-list');

          addToast({
            type: 'success',
            title: 'Lista finalizada!',
            description: 'Lista de contato com o cliente finalizada',
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao finalizar lista de contato com o cliente',
          description:
            'Ocorreu um erro ao finalizar lista de contato com o cliente.',
        });
      }
    },
    [
      addToast,
      clientProcess,
      history,
      locationState.client.id,
      locationState.id,
      user.id,
    ],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Contato com o Cliente</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="folder" label="Pasta" type="text" />

              <label>
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainer>

            <FormContainer>
              <InputWithLabel name="attendance_date" label="Data" type="date" />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default FinishContactClientListItem;
