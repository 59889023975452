/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer } from './styles';

import api from '../../../../services/api';

import LeftMenu from '../../../../components/LeftMenu';
import InputWithLabel from '../../../../components/InputWithLabel';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';

interface ContactClientFormData {
  attendance_date: string;
  folder: string;
  description: string;
  user_id: string;
  user_responsible_id: string;
}

interface Location {
  id: string;
  name: string;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

const NewContactClientList: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [clientProcess, setClientProcess] = useState<ClientProcess>();
  const [users, setUsers] = useState<User[]>([]);

  const locationState: Location = location.state as Location;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({ name: locationState.name });
    }
    api.get('/users').then((response) => {
      setUsers(response.data);
    });
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: ContactClientFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          attendance_date: Yup.string().required(
            'Data do andamento obrigatória',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.user_id = user.id;

        // const attendanceType = await api.get(
        //   '/attendance-types/name/Contato com o Cliente',
        // );

        // console.log(attendanceType);
        console.log(clientProcess);
        // const contacClientIdProd = '4f8987e1-44d3-4fe2-8e90-7d1403651644';
        // const contacClientIdDev = 'e513e99c-56ca-4468-adfa-18f875fccbf6';

        if (clientProcess) {
          // const processAttendance = await api.post('/process-attendance', {
          //   client_id: clientProcess?.client.id,
          //   attendance_type_id: contacClientIdProd,
          //   client_process_id: clientProcess?.id,
          //   description: data.description,
          //   attendance_date: data.attendance_date,
          // });

          await api.post('/contact-client-list', {
            client_id: clientProcess?.client.id,
            // process_attendance_id: processAttendance.data.id,
            contact_date: data.attendance_date,
            done: false,
            description: data.description,
            client_process_id: clientProcess?.id,
            user_id: data.user_id,
            user_responsible_id: data.user_responsible_id,
          });

          history.push('/contact-client-list');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Contato com Cliente criado',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar contato com o cliente.',
        });
      }
    },
    [addToast, clientProcess, history, user.id],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Novo Contato com Cliente</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="folder" label="Pasta" type="text" />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
              >
                <FiSearch size={30} />
              </Button>
              <label className="process-client">
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainer>

            <FormContainer>
              <InputWithLabel name="attendance_date" label="Prazo" type="date" />
            </FormContainer>

            <FormContainer>
              <Select
                name="user_responsible_id"
                label="Responsável"
                initialLabel="Selecione a Responsável"
                options={users.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
              />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default NewContactClientList;
